<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container xl fluid v-else>
            <v-row>
                <v-overlay :value="overlayDesgarga" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p>{{frase}}</p>
                    </div>
                </v-overlay>
                <v-col cols="12" xs="12" sm="12" md="12" class="center">
                    <div class="margen d-flex">
                        <hr class="vr" />
                        <p class="tblName">Cálculo anual</p>
                    </div>

                    <v-card class="borde-card elevation-0">
                        <v-card-text class="pa-8">
                            <ValidationObserver ref="form" v-slot="{invalid, validated}">
                                <v-row> 
                                    <v-col v-if="rol == 'root'" cols="12" xs="12" sm="4" md="4" class="pb-0 ma-0">
                                        <ValidationProvider>
                                            <v-autocomplete
                                                outlined
                                                label="Clientes"
                                                :items="clientes"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="cliente_value"
                                                class="pa-0 ma-0"
                                                required
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="4" md="4" class="pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="registrosPatronales"
                                            item_text="nombreClave"
                                            item_value="id"
                                            @dataMultiple="(data) => registrosPatronalesSeleccionados = data"
                                            rules="required"
                                            label="Registros patronales"
                                            ref="multRegistronal"
                                        >
                                        </VuetifyMultipleAutocomplete>
                                    </v-col>

                                    <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="4" md="4" class="pb-0 ma-0">
                                        <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Empresas"
                                                :items="empresas"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="empresaSeleccionada"
                                                class="pa-0 ma-0"
                                                :error-messages="errors"
                                                no-data-text="Datos no disponibles"
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'" cols="12" xs="12" sm="6" md="4" class="pb-0 ma-0" >
                                        <ValidationProvider v-slot="{ errors }" name="Año" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Año"
                                                :items="items_anios"
                                                item-text="nombreAnio"
                                                item-value="id"
                                                persistent-hint
                                                v-model="anio_id"
                                                class="pa-0 ma-0"
                                                :error-messages="errors"
                                                no-data-text="Datos no disponibles"
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="2" md="2" class=" pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="sucursales"
                                            item_text="nombre"
                                            @dataMultiple="(data)=> sucursalesSeleccionadas = data"
                                            label="Sucursales"
                                            ref="multSucursal"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="2" md="2" class=" pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="departamentos"
                                            item_text="nombre"
                                            @dataMultiple="(data)=> departamentosSeleccionados = data"
                                            label="Departamentos"
                                            ref="multDepartamento"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="4" md="4" class=" pb-0 ma-0">
                                       
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="tipos_nominas"
                                            item_text="claveDescripcion"
                                            @dataMultiple="(data)=> tiposNominasSeleccionadas = data"
                                            label="Tipo de nómina"
                                            ref="multTiposNomina"
                                        />
                                    </v-col>

                                    

                                    <v-col v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'" cols="12" xs="12" sm="6" md="4" class="pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="empleados"
                                            item_text="nombre_completo"
                                            @dataMultiple="(data) => empleadosSeleccionados = data"
                                            label="Empleados"
                                            ref="multEmpleados"
                                        >
                                        </VuetifyMultipleAutocomplete>
                                    </v-col>

                                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0 d-flex">
                                        <v-container fluid class="py-0 d-flex">
                                            <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="soloTimbrado" label="Calcular únicamente empleados timbrados"></v-checkbox>
                                            <v-tooltip top class="pb-7">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2 outlined_v_icon">info</v-icon>
                                                </template>
                                                <span class="pb-7 textTooltip">
                                                    En caso de no seleccionar esta opción, solo se <br>
                                                    calcularán los empleados acumulados.
                                                </span>
                                            </v-tooltip>
                                        </v-container>
                                    </v-col>

                                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="d-flex justify-end align-center text-center">
                                        <v-btn
                                            rounded
                                            class="btnModulo btnGeneral elevation-0 px-6 ml-3"
                                            @click="procesar()"
                                            :loading="loadingProcesar"
                                        >
                                            Procesar
                                        </v-btn>
                                    </v-col>

                                    <template v-if="showDeduccionesPercepciones">
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                            <v-divider class="divider-gray skiny"></v-divider>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                                            <ValidationProvider v-slot="{ errors }" name="Complemento para saldo a favor" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Complemento para saldo a favor"
                                                    :items="itemsPercepciones"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="valorPercepcion"
                                                    class="pa-0 ma-0"
                                                    required
                                                    :error-messages="errors"
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                                            <ValidationProvider v-slot="{ errors }" name="Complemento para saldo a cargo" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Complemento para saldo a cargo"
                                                    :items="itemsDeducciones"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="valorDeduccion"
                                                    class="pa-0 ma-0"
                                                    required
                                                    :error-messages="errors"
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                    </template>
                                </v-row>
                            </ValidationObserver>
                        </v-card-text>
                    </v-card>
                </v-col>



                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-card style="border-radius: 20px; background: transparent;" elevation="0">
                        <v-overlay :z-index="999" :value="overlayCargar" :absolute="true">
                            <div class="align-center justify-center text-center">
                                <v-progress-circular
                                    indeterminate
                                    :size="100"
                                    :width="7"
                                    color="#2DB9D1"
                                >
                                    <v-img
                                        src="/static/icon/favicon.ico"
                                        :width="60"
                                    ></v-img>
                                </v-progress-circular>
                                <p class="mt-5">Cargando...</p>
                            </div>
                        </v-overlay>
                        
                        
                        <v-card-text class="px-0 pb-30px pt-6" style="position:relative; background: transparent;">
                            <v-row class="pa-0 ma-0 areaConsulta informacionGeneralConsulta" style="border-radius: 20px; background: transparent;">
                                <v-col 
                                    cols="12" xs="12" sm="12" md="12" 
                                    class="pa-0 " 
                                    style="min-height:500px;"
                                    
                                >
                                    <div >
                                        <ValidationObserver ref="formTabla" v-slot="{ invalid }">
                                            <simple-table
                                                ref="tablaAnual"
                                                :isSelectable="true"
                                                :selected="selected_general"
                                                @selectAllEvent="selectAll"
                                                :rows="datosTabla"
                                                :columns="headers"
                                                :perPage="[10, 25, 50, 100, 1000]"
                                                :tableName="'Lista de cálculo anual de empleados'"
                                                :valCheck="'empleado_id'"
                                                :idTabla="'tablaAnual'"
                                            >
                                                <template slot="actionButtonsLeft">
                                                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pa-0 d-flex justify-end align-center">
                                                        <span class="exportar-en mr-2">
                                                            Exportar en
                                                        </span>
                                                        <v-btn
                                                            color="#FFF"
                                                            class="elevation-0 pt-0 pb-0 pl-0 pr-0 mr-5"
                                                            @click="guardar(false)"
                                                            height="42"
                                                            width="15"
                                                            min-width="50px"
                                                            style="border-radius: 15px; box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.08);"
                                                            :disabled="datosTabla.length == 0" 

                                                        >
                                                            <img src="/static/icon/xls.png" style="width:25px"/>
                                                        </v-btn>
                                                        <v-menu offset-y content-class="menuClassAcciones">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    dark
                                                                    class="btnAcciones pr-1 mr-0"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    elevation="0"
                                                                >
                                                                    Acciones 
                                                                    <template  v-for="(item,valor) in attrs" >
                                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#FFFFFF'>expand_less</v-icon>
                                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#FFFFFF'>expand_more</v-icon>
                                                                    </template>
                                                                </v-btn>
                                                                
                                                            </template>
                                                            <div class="btnAccionesContenedor">
                                                                
                                                                <div @click="descargarCartaAviso('masivo')" class="btnAccionesItem py-2">
                                                                    <span>Descargar carta de aviso</span>
                                                                </div>
                                                                <!-- <div @click="descargarConstanciaF37();" class="btnAccionesItem py-2">
                                                                    <span>Descargar constancia F37</span>
                                                                </div> -->
                                                                <div @click="vistaAcumulados('masivo')" class="btnAccionesItem py-2">
                                                                    <span>Vista de acumulados</span>
                                                                </div>
                                                                    
                                                            </div>
                                                        </v-menu>

                                                        <v-btn
                                                            class="btnModulo btnGeneral elevation-0 px-6"
                                                            @click="guardar(true)" 
                                                            :loading="isSaving"
                                                        >
                                                            Guardar
                                                        </v-btn>
                                                    </v-col>
                                                </template>
                                                <tbody slot="body" slot-scope="{ data }" class="tablaBody">
                                                    <tr :key="empleado.empleado_id" v-for="empleado in data" class="alto-tr">
                                                        <td style="width:115px !important; ">
                                                            <div>
                                                                <v-checkbox class="mb-5" primary hide-details :value="empleado.empleado_id" v-model="selected_general"></v-checkbox>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Calcular anual -->
                                                            <div>
                                                                {{empleado.claveEstatus == '01' ? 'SI':'NO'}}
                                                            </div>
                                                        </td>
                                                        <td class="py-1 my-1">
                                                            <!-- Motivo -->
                                                            <div v-if="!empleado.cambioVariable">
                                                                {{empleado.observaciones}}
                                                            </div>
                                                            <div v-if="empleado.cambioVariable">
                                                                <v-autocomplete
                                                                    outlined
                                                                    dense
                                                                    :items="itemsMotivos"
                                                                    item-text="nombre"
                                                                    item-value="valor"
                                                                    persistent-hint
                                                                    v-model="empleado.claveEstatus" 
                                                                    hide-details
                                                                    class="my-0 py-0"
                                                                >
                                                                </v-autocomplete>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Clave -->
                                                            <div>
                                                                {{empleado.clave}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Nombre del asalariado -->
                                                            <div>
                                                                {{empleado.empleado}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Estatus -->
                                                            <div>
                                                                {{empleado.fechaBaja == "" ? "Alta":"Baja"}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Fecha alta IMSS -->
                                                            <div>
                                                                {{dateFormat(empleado.fechaAlta)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Fecha baja -->
                                                            <div>
                                                                {{dateFormat(empleado.fechaBaja)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Percepciones totales -->
                                                            <div>
                                                                {{formatNum(empleado.percepcionTotal)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Percepciones exentas -->
                                                            <div>
                                                                {{formatNum(empleado.percepcionExcento)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Percepciones gravadas -->
                                                            <div>
                                                                {{formatNum(empleado.percepcionGravado)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Base gravable impuesto anual -->
                                                            <div v-if="empleado.claveEstatus == '01'">
                                                                {{formatNum(empleado.baseGravadoAnual)}}
                                                            </div>
                                                            <div v-else>
                                                                {{formatNum(0)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- ISR causado Art. 152 LISR -->
                                                            <div v-if="empleado.claveEstatus == '01'">
                                                                {{formatNum(empleado.isrCausado)}}
                                                            </div>
                                                            <div v-else>
                                                                {{formatNum(0)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Subsidio causado -->
                                                            <div v-if="empleado.claveEstatus == '01'">
                                                                {{formatNum(empleado.subsidioCausado)}}
                                                            </div>
                                                            <div v-else>
                                                                {{formatNum(0)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- ISR anual -->
                                                            <div v-if="empleado.claveEstatus == '01'">
                                                                {{formatNum(empleado.isrAnual)}}
                                                            </div>
                                                            <div v-else>
                                                                {{formatNum(0)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- ISR retenido en el ejercicio -->
                                                            <div v-if="empleado.claveEstatus == '01'">
                                                                {{formatNum(empleado.isrRetenidoEjercicio)}}
                                                            </div>
                                                            <div v-else>
                                                                {{formatNum(0)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- ISR a cargo -->
                                                            <div v-if="empleado.claveEstatus == '01'">
                                                                {{formatNum(empleado.isrACargo)}}
                                                            </div>
                                                            <div v-else>
                                                                {{formatNum(0)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- ISR a favor -->
                                                            <div v-if="empleado.claveEstatus == '01'">
                                                                {{formatNum(empleado.isrAFavor)}}
                                                            </div>
                                                            <div v-else>
                                                                {{formatNum(0)}}
                                                            </div>
                                                        </td>
                                                        
                                                        
                                                        <td>
                                                            <div>
                                                                <v-menu buttom left class="elevation-0 d-inline" content-class="mt-0 menuClassAccionesEmpleado"> 
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            icon 
                                                                            fab
                                                                            dark
                                                                            small
                                                                            class="elevation-0"
                                                                        >
                                                                            <template  v-for="(item,valor) in attrs" >
                                                                                <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                                                <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                                            </template>
                                                                        </v-btn>
                                                                    </template>

                                                                    <v-list>
                                                                        <v-list-item
                                                                            v-if="empleado.cambioVariable"
                                                                            @click="descargarCartaAviso('empleado', empleado)"
                                                                        >
                                                                            <v-list-item-title>Descargar carta de aviso</v-list-item-title>
                                                                        </v-list-item>
                                                                        <!-- <v-list-item
                                                                            @click="descargarConstanciaF37()"   
                                                                        >
                                                                            <v-list-item-title>Descargar constancia F37</v-list-item-title>
                                                                        </v-list-item> -->
                                                                        <v-list-item
                                                                            @click="vistaAcumulados('empleado', empleado)"
                                                                        >
                                                                            <v-list-item-title>Vista de acumulados</v-list-item-title>
                                                                        </v-list-item>
                                                                    </v-list>
                                                                </v-menu>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </simple-table>
                                        </ValidationObserver>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue';
//APIS
import clientesApi from "@/api/clientes";
import empresaApi from "@/api/empresas";
import apiAnios from '@/api/anual_fiscal';
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import sucursalApi from "@/api/sucursales";
import departamentoApi from "@/api/departamentos";
import apiTipoNomina from '@/api/nominas/tipoNomina';
import apiAjusteAnual from "@/api/nominas/ajusteAnual";

//COMPONENTS
import VuetifyMultipleAutocomplete from '@/components/VuetifyMultipleAutocomplete.vue';
import DatatableSimple from '@/components/simpletable/Datatable.vue';

//PLUGINS
import Notify from '@/plugins/notify';
import GetUrlImg from "@/plugins/Urlimagen";
import registros from '../../api/registros';

export default {
    components:{
        VuetifyMultipleAutocomplete,
        "simple-table": DatatableSimple,
    },
    data(){
        return {
            datosLogin                          : null,
            rol                                 : 'root',

            clientes                            : [],
            registrosPatronales                 : [],
            empresas                            : [],
            tipos_nominas                       : [],
            items_anios                         : null,
            nominas                             : [],
            empleados                           : [],

            cliente_value                       : null,
            registrosPatronalesSeleccionados    : [],
            empresaSeleccionada                 : null,
            anio_id                             : null,
            empleadosSeleccionados              : [],

            sucursales                          : [],
            sucursalesSeleccionadas             : [],
            departamentos                       : [],
            departamentosSeleccionados          : [],
            tipos_nominas                       : [],
            tiposNominasSeleccionadas           : [],
            soloTimbrado                        : false,

            overlayCargar                       : false,
            btnGuardar                          : false,
            isSaving                            : false,
            datosTabla                          : [],
            loadingProcesar                     : false,
            selected_general                    : [],
            masivo                              : false,

            headers: [
                { label: "Calcular anual",                  name: "aplicaCalculo",                  filterable: false,      showTooltip: false,     mensajeTooltip:"" },
                { label: "Motivo",                          name: "claveEstatus",                   filterable: false,      showTooltip: false,     mensajeTooltip:"" },
                { label: "Clave",                           name: "clave",                          filterable: false,      showTooltip: false,     mensajeTooltip:"" },
                { label: "Nombre del empleado",             name: "empleado",                       filterable: false,      showTooltip: false,     mensajeTooltip:"" },
                { label: "Estatus",                         name: "estatus",                        filterable: false,      showTooltip: false,     mensajeTooltip:"" },
                { label: "Fecha alta IMSS",                 name: "fechaAlta",                      filterable: false,      showTooltip: false,     mensajeTooltip:"" },
                { label: "Fecha baja",                      name: "fechaBaja",                      filterable: false,      showTooltip: false,     mensajeTooltip:"" },
                { label: "Percepciones totales",            name: "percepcionTotal",                filterable: false,      showTooltip: true,      mensajeTooltip:"Percepciones totales del empleado<br> acumulado al periodo actual. Este importe es<br> considerado para validar si el empleado<br> supero el tope de 400,000 anuales." },
                { label: "Percepciones exentas",            name: "percepcionExcento",              filterable: false,      showTooltip: true,      mensajeTooltip:"Percepciones exentas del empleado<br> acumulado al periodo actual." },
                { label: "Percepciones gravadas",           name: "percepcionGravado",              filterable: false,      showTooltip: true,      mensajeTooltip:"Percepciones gravadas del empleado<br> acumulado al periodo actual." },
                { label: "Base gravable impuesto anual",    name: "baseGravadoAnual",               filterable: false,      showTooltip: true,      mensajeTooltip:"Importe gravable a trabajadores que aplique<br> cálculo anual conforme al articulo 97 de LISR" },
                { label: "ISR causado Art. 152  LISR",      name: "isrCausado",                     filterable: false,      showTooltip: true,      mensajeTooltip:"Tarifa de impuesto anual que le corresponde<br> al empleado de acuerdo a las percepciones<br> gravadas en el ejercicio." },
                { label: "Subsidio causado",                name: "subsidioCausado",                filterable: false,      showTooltip: true,      mensajeTooltip:"Subsidio que le correspondió al cierre del ejercicio considerando el ajuste al subsidio causado." },
                { label: "ISR anual",                       name: "isrAnual",                       filterable: false,      showTooltip: true,      mensajeTooltip:"Impuesto anual: ISR causado menos subsidio<br> causado." },
                { label: "ISR retenido en el ejercicio",    name: "isrRetenidoEjercicio",           filterable: false,      showTooltip: true,      mensajeTooltip:"ISR retenido al cierre del ejercicio considerando<br> el reintegro de ISR " },
                { label: "ISR a cargo",                     name: "isrACargo",                      filterable: false,      showTooltip: true,      mensajeTooltip:"Impuesto a cargo del ejercicio, resulta<br> cuando el ISR anual es mayor al ISR retenido<br> en el ejercicio." },
                { label: "ISR a favor",                     name: "isrAFavor",                      filterable: false,      showTooltip: true,      mensajeTooltip:"Impuesto a favor del ejercicio, resulta cuando<br> el  ISR Retenido en el ejercicio es mayor al ISR<br> anual." },
                { label: "Opciones",                        name: "opciones",                       filterable: false,      showTooltip: false,     mensajeTooltip:"" }
            ],
            itemsMotivos: [
                {
                    nombre: 'Aplica calculo',
                    valor: '01'
                },
                {
                    nombre: 'Empleado comunico escrito de no calcular anual',
                    valor: '06'
                },

            ],
            showDeduccionesPercepciones : false,
            itemsDeducciones            : [],
            itemsPercepciones           : [],
            valorDeduccion              : null,
            valorPercepcion             : null,
            valorAnio                   : null,
            overlayDesgarga             : false,
            frase                       : "Guardando"
        }
    },
    watch:{
        cliente_value: function(val){
            this.cambiaCliente(val);
        },
        registrosPatronalesSeleccionados:{
            deep:true,  
            handler(val){
                this.cambiaRegistroPatronal();
            }
        },
        empresaSeleccionada:function(val){
            this.getAnios();
            this.cambiaEmpresas(val);
            this.getTiposNominas();
        },
         /*Descripcion: Ejectuta cambiaScursal() mandando el nuevo valor de esta variable*/
        sucursalesSeleccionadas:{
            deep:true,  
            handler(val){
                this.cambiaSucursales(val);
            }
        },

        /*Descripcion: Ejectuta getEmpleados() */
        departamentosSeleccionados:{
            deep:true,  
            handler(val){
                this.getEmpleados();
            
            }
        },
        tiposNominasSeleccionadas:{
            deep:true,
            handler(val){
                this.getEmpleados();
            
            }
        },
        anio_id:function(val){
            this.getEmpleados();
        },
        selected_general:function(value) {
            if(value.length > 0 ){
                this.masivo =  true;
            }
            else{
                this.masivo =  false;
            }
        },
    },
    methods:{
        init() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;

            //this.urlImg = GetUrlImg.getURL();
            

            if (this.rol == "root") {
                this.getClientes();
            }
 
            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
            }

            if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresaSeleccionada = this.datosLogin.empresa_id;
            }

            if(this.rol == "admin-sucursal"){
                Notify.ErrorToast("Tu rol no tiene acceso a este módulo");
            }

        },
        /**
         * @method getClientes Este método realiza la petición a la API de clientes.
         * @description Este método se ejecuta al seleccionar al iniciar la vista. El resultado se almacena en el array clientes=[]
        */
        async getClientes() {
            let param = { 
                activo: true, 
                paginate: false 
            };
            await clientesApi.getClientes(param).then(response => {
                this.clientes = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes");
            });
        },

        async cambiaCliente(cliente){
            if(cliente == null){
                return;
            }
            this.registrosPatronales = []

            let param = { 
                activo: true, 
                paginate: false, 
                cliente_id: cliente 
            };
            await apiRegistroPatronal.find(param).then(response => {
                //this.registrosPatronales = response.data;
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.nombreClave = item.nombre + ' - ' + item.clave
                        this.registrosPatronales.push(item);
                    })
                }
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los registros patronales.");
            });
        },

        /**
         * @method cambiaRegistroPatronal Este método realiza la petición a la API de Empresas.
         * @description Este método se almacena en el array empresas=[]. Se ejecuta al seleccionar al menos una empresa.
        */
        async cambiaRegistroPatronal(){
            let param = {   
                activo: true, 
                paginate: false, 
                cliente_id: this.cliente_value,
                arrayRegistroPatronal: this.registrosPatronalesSeleccionados.length == 0 ? null:JSON.stringify(this.registrosPatronalesSeleccionados)
            };

            if(this.registrosPatronalesSeleccionados.length == 0){
                this.empresas = []
                return
            }

            await empresaApi.getEmpresas(param).then(response => {
                this.empresas = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },


        async cambiaEmpresas(item) {
            if(item == null){
                this.sucursales = [];
                return;
            }
            let arrayEmpresas = [];
            arrayEmpresas.push(item);
            let parametros = { activo: true, paginate: false, arrayEmpresa: JSON.stringify(arrayEmpresas)};
            await sucursalApi.getSucursales(parametros).then(response => {
                this.sucursales = response.data;
                this.sucursales.map(suc => suc.nombre += " - "+suc.nombre_empresa);
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
            });
        },

        cambiaSucursales(items) {
            if(items.length == 0){
                this.departamentos = [];
                return;
            }
            
            let parametros = { activo: true, paginate: false, arraySucursal: JSON.stringify(items) };
            departamentoApi.getDepartamentos(parametros).then((response)=>{
                this.departamentos = response.data;
                this.departamentos.map(depto => depto.nombre += " - "+depto.nombre_sucursal);
                //this.getEmpleados();
            })
            .catch(error => {
                this.resetValues();
                Notify.ErrorToast(error);
            });
        },

        async getTiposNominas() {
            if(this.empresaSeleccionada == null){
                this.tipos_nominas = [];
                return
            }
            let arrayEmpresas = [];
            arrayEmpresas.push(this.empresaSeleccionada);
            let param = {
                activo      : true,
                paginate    : false,
                cliente_id  : this.cliente_value,
                arrayEmpresa: JSON.stringify(arrayEmpresas)
            }
            this.tipos_nominas = [];
            await apiTipoNomina.find(param).then((response) => {
                this.tipos_nominas = response.data;
                this.tipos_nominas.map(tipo_nomina => tipo_nomina.claveDescripcion = tipo_nomina.clave + ' - ' + tipo_nomina.descripcion+ " - "+tipo_nomina.nombre_empresa);
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los tipos de nómina");
            })
        },

        /**
         * @method getAnios Este método realiza la petición a la API de años.
         * @description Este método se ejecuta al seleccionar al menos una empresa. El resultado se almacena en el array items_anios=[]
        */
        async getAnios() {
            if(this.empresaSeleccionada == null){
                this.items_anios = []
                return
            }
            let param = {
                activo      : true,
                paginate    : false,
                cliente_id  : this.cliente_value,
                empresa_id  : this.empresaSeleccionada
            }

            await apiAnios.find(param).then((response) => {
                this.items_anios = response.data;
                this.items_anios.map(emp => emp.nombreAnio = emp.anio.nombre);
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los años.");
            })
        },


        /**
         * @method getEmpleados Este método realiza la petición a la API de empleados
         * @description Realiza petición a la API de empleados cuando la empresa es seleccionada y el resultado lo almacena en el array empleados[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
        */
        getEmpleados(){
            let arrayEmpresas = [];
            arrayEmpresas.push(this.empresaSeleccionada);


            let parametros = { 
                anio_fiscal_id : this.anio_id,
                empresa_id : arrayEmpresas,
                registro_patronal_id : this.registrosPatronalesSeleccionados,
                sucursal_id : this.sucursalesSeleccionadas.length == 0 ? null:this.sucursalesSeleccionadas, 
                departamentos : this.departamentosSeleccionados.length == 0 ? null:this.departamentosSeleccionados,
                tipo_nominas:this.tiposNominasSeleccionadas.length == 0 ? null:this.tiposNominasSeleccionadas
            };

            if(this.registrosPatronalesSeleccionados.length == 0){
                this.empleados = []
                return
            }
            if(this.empresaSeleccionada == null){
                this.empleados = [];
                return;
            }
            if(this.anio_id == null){
                this.empleados = [];
                return;
            }

            apiAjusteAnual.getEmpleados(parametros).then((response)=>{
                this.empleados = response.data;
                //this.empleados.map(emp => emp.nombre_completo += " - "+emp.nombre_empresa + " - "+emp.nombre_sucursal + " - "+emp.nombre_departamento);
            })
            .catch(error => {
                 Notify.ErrorToast("No se pudo realizar la petición de empleados.");
            });
        },
        async procesar(){

            this.$refs.form.validate().then(success => {
                if(!success) {
                    return;
                }
            });

            let arrayEmpresas = [];
            arrayEmpresas.push(this.empresaSeleccionada);


            let parametros = { 
                anio_fiscal_id : this.anio_id,
                empresa_id : arrayEmpresas,
                registro_patronal_id : this.registrosPatronalesSeleccionados,
                empleados : this.empleadosSeleccionados,
                sucursal_id : this.sucursalesSeleccionadas.length == 0 ? null:this.sucursalesSeleccionadas, 
                departamentos : this.departamentosSeleccionados.length == 0 ? null:this.departamentosSeleccionados,
                tipo_nominas:this.tiposNominasSeleccionadas.length == 0 ? null:this.tiposNominasSeleccionadas,
                solo_timbrado : this.soloTimbrado
            };

            if(this.registrosPatronalesSeleccionados.length == 0){
                this.empleados = []
                return
            }

            this.loadingProcesar = true;

            apiAjusteAnual.ajusteAnual(parametros).then((response)=>{
                this.datosTabla                     = response.registros;
                this.itemsDeducciones               = response.deducciones;
                //this.itemsPercepciones              = response.percepciones;
                this.itemsPercepciones              = response.percepciones.filter(per => per.codigo != 'O011');
                this.valorDeduccion                 = response.isrCargo.id;
                this.valorPercepcion                = response.isrFavor.id;
                this.valorAnio                      = response.anioFiscal.id;
                this.showDeduccionesPercepciones    = true;
                this.loadingProcesar = false;
            })
            .catch(err => {
                this.loadingProcesar = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });
        },
        async guardar(guardar){
            let self                = this;
            let url                 = "calculo-nomina/guardar-ajuste-anual";
            this.overlayDesgarga    =  true;
            this.frase              = "Descargando";
            const FileDownload      = require("js-file-download");
            let nombre              = "calculo-anual.xlsx";
            
            this.datosTabla.forEach( item => {
                if(item.claveEstatus == '06'){
                    item.aplicaCalculo = false;
                }
            })
            let parametros          = { 
                anio_fiscal_id  : this.valorAnio, 
                cargo_id        : this.valorDeduccion,
                favor_id        : this.valorPercepcion,
                registros       : this.datosTabla,
                guardar         : guardar
            }
            const object = parametros;

            try {
                await Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: object,
                })
                .then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    self.overlayDesgarga = false;
                    if(guardar){
                        if (self.rol == "root") {
                            this.cliente_value = null;
                        }
                        this.registrosPatronalesSeleccionados = [];
                        this.empresaSeleccionada = null;
                        this.anio_id = null;
                        this.empleadosSeleccionados = [];
                        this.showDeduccionesPercepciones = false;

                        this.valorAnio = null
                        this.valorDeduccion = null
                        this.valorPercepcion = null
                        this.datosTabla = []
                    }
                })
                .catch(async (error)=>{
                    self.overlayDesgarga = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                });
            } catch (err) {
                this.overlayDesgarga = false;
                Notify.ErrorToast("No se pudo realizar la petición.");
            }
        },

        /**
         * @method dateFormat Esta método parsea una fecha
         * @description Convierte una fecha de formato DD-MM-AAAA => DD/MM/AAAA. Se usa para el modal y la tabla 
         * @param {string} fecha formato DD-MM-AAAA
         * @return {string} devuelve un string en formato DD/MM/AAAA
        */
        dateFormat(fecha) {
            if(fecha == 'null' || fecha == ""){
                return
            }

            let fecha2 = fecha.split("-");
            return fecha2[0] + " / " + fecha2[1]+ " / " + fecha2[2];
        },

        /**
         * @method formatNum Esta método parsea un número
         * @description Convierte un número a formato moneda, formato pesos mexicanos 
         * @param {number} num Valor númerico que puede tener números decimales.
         * @return {string} devuelve un string en formato $0.00
        */
        formatNum(numero){
            let num = parseFloat(numero)
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },
        selectAll(isSelected, items) {
            this.selected_general = items;
        },
        async descargarCartaAviso(tipo, empleado = null){
            let self                = this;
            let url                 = "reportes/no-calculo-anual";
            this.overlayDesgarga    =  true;
            this.frase              = "Descargando";
            const FileDownload      = require("js-file-download");
            let nombre              = '';
            let parametros          = {};

            let empleadosTabla      = [];
            let idEmpleados         = [];


            if(tipo == 'masivo' && this.selected_general.length > 1){
                nombre              = "Carta_de_aviso.zip";
                empleadosTabla = await this.datosTabla.filter((empleado) => this.selected_general.includes(empleado.empleado_id) && (empleado.cambioVariable))
                if(empleadosTabla.length == 0 ){
                    Notify.ErrorToast("Los empleados seleccionados no se les puede descargar las cartas de aviso.");
                    this.overlayDesgarga    =  false;
                    return;
                }
                else if(empleadosTabla.length == 1){
                    nombre              = `${empleadosTabla[0].empleado}.docx`;
                    idEmpleados = empleadosTabla.map(element => { return element.empleado_id})

                    parametros          = { 
                        anio_fiscal_id  : this.valorAnio, 
                        empleados       : idEmpleados
                    }
                }
                else{
                    idEmpleados = empleadosTabla.map(element => { return element.empleado_id})

                    parametros          = { 
                        anio_fiscal_id  : this.valorAnio, 
                        empleados       : idEmpleados
                    }
                }   
            }
            else if(tipo == 'masivo' && this.selected_general.length == 1){
                
                let datos           = await this.datosTabla.find(element => element.empleado_id === this.selected_general[0]);
                nombre              = `${datos.empleado}.docx`;
                if(datos.cambioVariable == false){
                    Notify.ErrorToast("El empleado seleccionado no se le puede descargar la carta de aviso.");
                    this.overlayDesgarga    =  false;
                    return;
                }
                parametros          = { 
                    anio_fiscal_id  : this.valorAnio, 
                    empleados       : this.selected_general
                }
            }
            else if(tipo == 'masivo' && this.selected_general.length == 0){
                Notify.ErrorToast("Se requiere seleccionar al menos un empleado");
                this.overlayDesgarga    = false;
                return;
            }
            else if(tipo == 'empleado'){
                nombre              = `${empleado.empleado}.docx`;
                let itemEmpleado = [];
                itemEmpleado.push(empleado.empleado_id);
                parametros          = { 
                    anio_fiscal_id  : this.valorAnio, 
                    empleados       : itemEmpleado
                }
            }
            
            const object = parametros;
            try {
                await Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: object,
                })
                .then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    self.overlayDesgarga = false;
                })
                .catch(async (error)=>{
                    self.overlayDesgarga = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                });
            } catch (err) {
                this.overlayDesgarga = false;
                Notify.ErrorToast("No se pudo realizar la petición.");
            }
        },
        async descargarConstanciaF37(){
            let self                = this;
            let url                 = "reportes/constanciaF37";
            this.overlayDesgarga    =  true;
            this.frase              = "Descargando";
            const FileDownload      = require("js-file-download");
            let nombre = '';
            let parametros = {};


            this.overlayDesgarga    =  false; // quitar esta linea
            return; // quitar esta linea


            if(tipo == 'masivo' && this.selected_general.length > 1){
                nombre              = "Carta_de_aviso.zip";
                parametros          = { 
                    anio_fiscal_id  : this.valorAnio, 
                    empleados       : this.selected_general
                }
            }
            else if(tipo == 'masivo' && this.selected_general.length == 1){
                
                let datos           = await this.datosTabla.find(element => element.empleado_id === this.selected_general[0]);
                nombre              = `${datos.empleado}.docx`;
                parametros          = { 
                    anio_fiscal_id  : this.valorAnio, 
                    empleados       : this.selected_general
                }
            }
            else if(tipo == 'masivo' && this.selected_general.length == 0){
                Notify.ErrorToast("Se requiere seleccionar al menos un empleado");
                this.overlayDesgarga    = false;
                return;
            }
            else if(tipo == 'empleado'){
                nombre              = `${empleado.empleado}.docx`;
                let itemEmpleado = [];
                itemEmpleado.push(empleado.empleado_id);
                parametros          = { 
                    anio_fiscal_id  : this.valorAnio, 
                    empleados       : itemEmpleado
                }
            }
            
            const object = parametros;
            try {
                await Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: object,
                })
                .then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    self.overlayDesgarga = false;
                })
                .catch(async (error)=>{
                    self.overlayDesgarga = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                });
            } catch (err) {
                this.overlayDesgarga = false;
                Notify.ErrorToast("No se pudo realizar la petición.");
            }
        },
        async vistaAcumulados(tipo, empleado = null){
            let self                = this;
            let url                 = "calculo-nomina/reporte-ajuste-anual-detallado";
            this.overlayDesgarga    =  true;
            this.frase              = "Descargando";
            const FileDownload      = require("js-file-download");
            let nombre = '';
            let parametros = {};

            let empleadosTabla      = [];


            if(tipo == 'masivo' && this.selected_general.length > 1){
                nombre              = "Vista_acumulados.zip";
                empleadosTabla      = await this.datosTabla.filter((empleado) => this.selected_general.includes(empleado.empleado_id))
                parametros          = { 
                    anio_fiscal_id  : this.valorAnio,
                    registros       : empleadosTabla,
                    guardar         : false
                }
            }
            else if(tipo == 'masivo' && this.selected_general.length == 1){
                
                empleadosTabla      = await this.datosTabla.filter((empleado) => this.selected_general.includes(empleado.empleado_id))
                nombre              = `${empleadosTabla[0].empleado}.xlsx`;
                parametros          = { 
                    anio_fiscal_id  : this.valorAnio,
                    registros       : empleadosTabla,
                    guardar         : false
                }
            }
            else if(tipo == 'masivo' && this.selected_general.length == 0){
                Notify.ErrorToast("Se requiere seleccionar al menos un empleado");
                this.overlayDesgarga    = false;
                return;
            }
            else if(tipo == 'empleado'){
                nombre              = `${empleado.empleado}.xlsx`;
                let itemEmpleado = [];
                itemEmpleado.push(empleado);
                parametros          = { 
                    anio_fiscal_id  : this.valorAnio, 
                    registros       : itemEmpleado,
                    guardar         : false
                }
            }
            
            const object = parametros;
            try {
                await Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: object,
                })
                .then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    self.overlayDesgarga = false;
                })
                .catch(async (error)=>{
                    self.overlayDesgarga = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                });
            } catch (err) {
                self.overlayDesgarga = false;
                Notify.ErrorToast("No se pudo realizar la petición.");
            }
        },

         /**
         * @method redireccionar Método que redirecciona a una vista
         * @description Método que redirecciona a la vista dashboard si el no es root, admin, admin-empresa
         */
        redireccionar(){
            this.$router.push('/dashboard');
        },
    },
    created() {
        this.init();
    },
    mounted(){
        if(document.querySelector('#tablaAnual')){
            document.querySelector('.emptyTable').style.width     = "3000px";
            document.querySelector('.emptyTable').style.minWidth   = "3000px";
        }
    },
    updated(){
        if(document.querySelector('#tablaAnual')){
            document.querySelector('.emptyTable').style.width     = "3000px";
            document.querySelector('.emptyTable').style.minWidth   = "3000px";
        }
        //if(document.querySelector('#tablaAnual')){
        //    document.querySelector('.tablaBody').style.maxHeight    = "none";
        //    document.querySelector('.tablaBody').style.width     = "4700px";
        //    document.querySelector('.tablaBody').style.minWidth   = "4000px";
        //    document.querySelector('.tablaBody').style.background   = "#fff";
        //}
    }
}
</script>

<style scoped>
    .tablaBody{
        width: 4700px;
        max-height: none;
        min-width: 4000px;
    }
    .margen {
		height: 48px;
		margin-bottom: 20px;
		margin-left: 1px;
	}

    .btnGeneral {
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 13px !important;
        line-height: 18px !important;
        background-color: #1E2245 !important;
        border-radius: 30px !important;
        color: white !important;
        height: 40px !important;
        outline: none;
        text-transform: none !important;
        margin-left: 3%;
        transition: all .2s;
    }

    .areaConsulta{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 37px;
        color: #828282;
    }

    .informacionGeneralConsulta{
        border: 1px solid transparent;
        box-sizing: border-box;
        border-radius: 15px;
    }



    

    .exportar-en{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .custom-row-true{
        background: #FFCDD2;
    }
    .custom-row-false{
        background: none;
    }

    .v-btn-hover:hover:before {
      background-color: transparent;
      
    }
    .v-btn-hover:before {
        display: none;
    }
    .custom-border{
        border-width:1px; 
        border-color:#E0E0E0 ;
        border-right-style: solid;
    }


    .menuClassAcciones{
        /* box-shadow: none; */
        border-radius: 10px!important;
        margin-top:15px;
    }
    .btnAcciones {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
    }
    .btnAccionesContenedor {
        width: 300px;
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        background-color: #FFF;
    }
    div.btnAccionesItem {
        width: 100%;
        cursor: pointer;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesItem span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
    div.btnAccionesItem:hover {
        background: #F6F6F8;
    }
    .menuClassAccionesEmpleado{
        border-radius: 10px!important;
    }

    .divider-gray{
        margin-left:-1px;
        border: 1.5px solid #E0E0E0;
        background:#E0E0E0;
    }
    .skiny{
        margin-top:1px;
        border: .5px solid #E0E0E0;
    }
</style>